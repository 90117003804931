import { SaveBulkUserRequest } from 'mapistry-shared';
import http from './httpClient';
import * as t from './types';

type FetchUserResponse = t.User;
export type FetchUsersResponse = t.UserPartialWithUserName[];

export async function fetchCurrentUser() {
  const res = await http.get<FetchUserResponse>(`/user`);
  return res.data;
}

export type UpdateUserVariables = {
  userId: string;
  userValues: t.UserValues;
};

export type UpdateUserResponse = t.User;

export async function updateUser(variables: UpdateUserVariables) {
  const { userId, userValues } = variables;
  const res = await http.put<UpdateUserResponse>(
    `/users/${userId}`,
    userValues,
  );
  return res.data;
}

export interface FetchAllUsersOnProjectProps {
  projectId?: string;
  includeArchived?: boolean;
}

export async function fetchAllUsersOnProject({
  projectId,
  includeArchived = false,
}: FetchAllUsersOnProjectProps) {
  const res = await http.get<FetchUsersResponse>(
    `/api/projects/${projectId}/users`,
    { params: { includeArchived } },
  );
  return res.data;
}

export type FetchOrgUsersResponse = t.UserPartialWithEmail[];

export interface FetchAllUsersOnOrganizationProps {
  organizationId: string;
}

export async function fetchAllUsersOnOrganization({
  organizationId,
}: FetchAllUsersOnOrganizationProps) {
  const res = await http.get<FetchOrgUsersResponse>(
    `/api/v2/organizations/${organizationId}/users`,
  );
  return res.data;
}

type SaveBulkUserResponse = {
  success: true;
};

export async function saveUsersBulk(
  userDetails: SaveBulkUserRequest[],
): Promise<SaveBulkUserResponse> {
  const res = await http.post<SaveBulkUserResponse>(
    `/api/v2/users/bulk`,
    userDetails,
  );
  return res.data;
}

type ActivateUsersBulkResponse = {
  success: true;
};

export async function activateUsersBulk(
  emails: string[],
): Promise<ActivateUsersBulkResponse> {
  const res = await http.post<ActivateUsersBulkResponse>(
    `/api/v2/users/activate/bulk`,
    emails,
  );
  return res.data;
}

export async function fetchIsRecoveryQuestionSet() {
  const res = await http.get<{ isRecoveryQuestionSet: boolean }>(
    `/user/check_recovery_question`,
  );
  return res.data;
}
